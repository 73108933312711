/* eslint-disable */
import axiosInstance from '../../utils/axios';
import Vue from 'vue';
const getDefaultState = () => ({
  url: '/recommendations',
  list: [],
  item: null
});
const recommendations = {
  namespaced: true,
  state: () => getDefaultState(),
  mutations: {
    setRecommendations(state, value) {
      state.list = value;
    },
    setRecommendation(state, value) {
      state.item = value;
    }
  },
  actions: {
    async load({ state, commit }) {
      const { data } = await axiosInstance.get(state.url);
      commit('setRecommendations', data);
    },
    async loadById({ state, commit }, id) {
      const { data } = await axiosInstance.get(`${state.url}/${id}`);
      commit('setRecommendation', data);
    },
    async create({ state }, data) {
      await axiosInstance.post(`${state.url}`, data);
    },
    async edit({ state }, data) {
      await axiosInstance.put(`${state.url}/${data.id}`, data);
    },
    async delete({ state }, id) {
      await axiosInstance.delete(`${state.url}/${id}`);
    }
  },

};
export default recommendations;

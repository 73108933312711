<template>
  <div>
    <v-app-bar>
      <v-app-bar-nav-icon class="mr-3" @click="handleSidebarToogle"></v-app-bar-nav-icon>

      <div class="search">
        <v-autocomplete
          :items="candidates"
          :item-text="candidateName"
          item-value="id"
          item-color="primary"
          flat
          hide-no-data
          hide-details
          placeholder="Name search"
          v-on:change="changeRoute"
          solo
        ></v-autocomplete>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex align-center ml-1">
        <div class="mx-5 d-none d-sm-flex">{{ user.name }}</div>
        <v-avatar class="hideUser">
          <img :src="user.image" />
        </v-avatar>
        <v-btn rounded class="mx-2 theme-color" @click.native="logout()">
          <v-icon>fas fa-sign-out-alt</v-icon>
        </v-btn>
      </div>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: 'Header',
  data: () => ({ drawer: null }),
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
    candidates() {
      return this.$store.state.candidates.list;
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout');
    },
    handleSidebarToogle() {
      this.$emit('onSidebarToogle');
    },
    candidateName(candidate) {
      return candidate.firstName + ' ' + candidate.lastName;
    },
    changeRoute(id) {
      this.$router.push({ path: `/candidates/${id}` });
    }
  }
};
</script>

<style scoped>
.search {
  min-width: 160px;
  width: 50%;
}
@media (max-width: 400px) {
  .hideUser {
    display: none;
  }
}
</style>

import axiosInstance from '../../utils/axios';

const getDefaultState = () => ({
  url: '/tags',
  list: []
});

const tags = {
  namespaced: true,
  state: () => getDefaultState(),
  mutations: {
    setTags(state, value) {
      state.list = value;
    }
  },
  actions: {
    async load({ state, commit }) {
      const loadResult = await axiosInstance.get(state.url);
      commit('setTags', loadResult.data);
    },
    async getById({ state }, { id }) {
      const tagByIdResult = await axiosInstance.get(`${state.url}/${id}`);
      return tagByIdResult.data;
    },

    async create({ state }, data) {
      await axiosInstance.post(`${state.url}`, data);
    },

    async edit({ state }, data) {
      const editedTagResult = await axiosInstance.put(`${state.url}/${data.id}`, data);
      return editedTagResult;
    },

    async delete({ state }, { id }) {
      await axiosInstance.delete(`${state.url}/${id}`);
    }
  }
};

export default tags;

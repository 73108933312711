import axiosInstance from '../../utils/axios';
const getDefaultState = () => ({
  url: '/candidates/archived',
  list: []
});
const archivedCandidates = {
  namespaced: true,
  state: () => getDefaultState(),
  mutations: {
    setArchivedCandidates(state, value) {
      state.list = value;
    }
  },
  actions: {
    async load({ state, commit }) {
      const { data } = await axiosInstance.get(state.url);
      commit('setArchivedCandidates', data);
    },
    async unArchive({ state, dispatch }, id) {
      await axiosInstance.put(`${state.url}/${id}`, { archived: false });
      await dispatch('load');
    },

    async delete({ state }, id) {
      await axiosInstance.delete(`${state.url}/${id}`);
    }
  }
};
export default archivedCandidates;

import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    component: () => import('../views/LandingPage'),
    meta: {
      guestOnly: true
    }
  },
  {
    path: '/',
    component: () => import('../views/candidates/index.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/candidates/:id',
    component: () => import('../views/candidatePage/index'),
    meta: {
      requiresAuth: true
    },
    props: true
  },
  {
    path: '/settings/tags',
    name: 'AdminTags',
    component: () => import('../views/adminTags')
  },
  {
    path: '/archivedCandidates',
    name: 'ArchivedCandidates',
    component: () => import('../views/archivedCandidates'),
  },
  {
    path: '/settings/topics',
    name: 'AdminTopics',
    component: () => import('../views/adminTopics')
  },
  {
    path: '/settings/positions',
    name: 'AdminPositions',
    component: () => import('../views/adminPositions')
  },
  {
    path: '/settings/templates',
    name: 'AdminTemplates',
    component: () => import('../views/adminTemplates')
  },

];

const router = new VueRouter({
  mode: 'history',
  routes
});

router.beforeEach((to, from, next) => {
  const isLogged = store.getters['auth/isLogged'];
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (isLogged) return next();
    return next({
      path: '/login'
    });
  } else if (to.matched.some(record => record.meta.guestOnly)) {
    if (!isLogged) return next();
    return next({ path: '/' });
  }
  return next();
});

export default router;

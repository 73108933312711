<template>
  <v-app>
    <template v-if="isLogged">
      <Header @onSidebarToogle="handleSidebarToogle" />
      <Sidebar :open="drawer" />
    </template>

    <v-main class="d-flex" tag="div">
      <v-container>
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Sidebar from './views/skeleton/Sidebar.vue';
import Header from './views/skeleton/Header.vue';

export default {
  components: {
    Header,
    Sidebar
  },
  data: () => ({
    drawer: false
  }),
  computed: {
    isLogged() {
      return this.$store.getters['auth/isLogged'];
    }
  },
  created() {
    // this.$store.dispatch('init');
  },
  methods: {
    handleSidebarToogle() {
      this.drawer = !this.drawer;
    }
  }
};
</script>

<style>
.v-btn,
.theme-color {
  background-color: var(--v-primary-base);
}
.v-btn > span {
  color: #ffffff;
}
.v-btn.v-btn--icon.v-btn--round.v-btn--text.v-size--default {
  background-color: transparent;
}
.v-btn.v-btn--icon.v-btn--round.v-btn--text.v-size--default > span {
  color: var(--v-primary-base);
}
button.v-btn--is-elevated.v-btn--has-bg.v-btn--round,
.theme-color {
  background-color: var(--v-primary-base) !important;
}
.v-container {
  background-color: var(--v-primary-base);
  color: var(--v-primary-base);
}
.delete:hover:before {
  color: #ff5252;
}
.edit:hover:before {
  color: var(--v-primary-base);
}
.restore:hover:before {
  color: #4caf50;
}
</style>

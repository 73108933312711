const config = {
  development: {
    APIbaseURL: 'https://apifeedback.cloverdynamics.com',
    googleAuth: {
      clientId: '770027500710-c4f11lanjr85tp2sdt7hbbc4kb0of9dv.apps.googleusercontent.com'
    },
    whiteListDomains: ['cloverdynamics.com']
  }
};

export default config.development;

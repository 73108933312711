import axiosInstance from '../../utils/axios';

const getDefaultState = () => ({
  url: '/positions',
  list: []
});
const positions = {
  namespaced: true,
  state: () => getDefaultState(),
  mutations: {
    setPositions(state, value) {
      state.list = value;
    }
  },
  actions: {
    async load({ state, commit }) {
      const allPositionsResult = await axiosInstance.get(`${state.url}/`);
      commit('setPositions', allPositionsResult.data);
    },
    async loadPositionById({ state }, { id }) {
      const positionByIdResult = await axiosInstance.get(`${state.url}/${id}`);
      return positionByIdResult.data;
    },
    async create({ state }, data) {
      await axiosInstance.post(`${state.url}`, data);
    },
    async edit({ state }, data) {
      const editedPositionResult = await axiosInstance.put(`${state.url}/${data.id}`, data);
      return editedPositionResult;
    },
    async delete({ state }, { id }) {
      await axiosInstance.delete(`${state.url}/${id}`);
    }
  },
  getters: {
    allPositions: state => state.list,
    positionById: state => id => state.list.filter(position => position.id === id),
    positionIdByName: state => name => state.list.find(position => position.name === name)
  }
};
export default positions;

import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: 'fa'
  },
  theme: {
    options: {
      customProperties: true
    },
    light: true,
    dark: false,
    themes: {
      light: {
        primary: '#33205A',
        secondary: '#000000',
        error: '#FF5252'
      },
      dark: {
        primary: '#a173ff',
        secondary: '#FFFFFF',
        error: '#FF5252'
      }
    }
  }
});

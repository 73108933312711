<template>
  <v-navigation-drawer :value="open" :clipped="$vuetify.breakpoint.lgAndUp" app width="300">
    <v-btn @click.native="toggleTheme()" width="100%">
      <v-icon color="primary">fas fa-moon</v-icon>
    </v-btn>
    <v-list dense>
      <template v-for="item in items">
        <v-list-group
          :key="item.text"
          v-if="item.subItems"
          no-action
          v-model="item.active"
          color="secondary"
        >
          <template v-slot:activator link :to="item.link">
            <v-list-item-action left>
              <v-icon color="primary">{{ item.icon }}</v-icon>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </template>

          <v-list-item v-for="subItem in item.subItems" :key="subItem.text" link :to="subItem.link">
            <v-list-item-avatar left>
              <v-icon color="primary">{{ subItem.icon }}</v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title v-text="subItem.text"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>

        <v-list-item v-else :key="item.text" link :to="item.link">
          <v-list-item-action left>
            <v-icon color="primary">{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
const items = [
  {
    icon: 'fa-home',
    text: 'Dashboard',
    link: '/'
  },
  {
    icon: 'fa-user-cog',
    text: 'Settings',
    subItems: [
      {
        icon: 'fa-user-plus',
        text: 'Positions',
        link: '/settings/positions'
      },
      {
        icon: 'fa-book',
        text: 'Topics',
        link: '/settings/topics'
      },
      {
        icon: 'fa-tags',
        text: 'Tags',
        link: '/settings/tags'
      },
      {
        icon: 'fa-keyboard',
        text: 'Templates',
        link: '/settings/templates'
      }
    ]
  },
  {
    icon: 'fa-archive',
    text: 'Archive',
    link: '/archivedCandidates'
  }
];

export default {
  props: {
    open: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    items
  }),
  methods: {
    toggleTheme: function() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
    }
  }
};
</script>

import axios from 'axios';
import config from '../config';
import store from '../store/index';

const axiosInstance = axios.create({
  baseURL: config.APIbaseURL || 'http://localhost:3001',
  headers: {
    accept: 'application/json',
  }
});

axiosInstance.interceptors.request.use(
  config => {
    config.headers.authorization = store.state.auth.user.accessToken;
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (401 === error.response.status) {
    store.dispatch('auth/logout');
  } else {
    return Promise.reject(error);
  }
});

export default axiosInstance;

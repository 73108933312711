import router from '../../router';

const getDefaultState = () => {
  const user = JSON.parse(localStorage.getItem('user'));
  const token = localStorage.getItem('token');

  return {
    user: user,
    token: token
  };
};

const auth = {
  namespaced: true,
  state: getDefaultState(),
  getters: {
    isLogged: (state) => !!state.token
  },
  mutations: {
    login(state, { user, token }) {
      state.token = token;
      state.user = user;
    },
    logout(state) {
      state.user = null;
      state.token = null;
    }
  },
  actions: {
    login(state, { user, token }) {
      state.commit('login', { user, token });
      localStorage.setItem('user', JSON.stringify(user));
      localStorage.setItem('token', token);
      router.push('/');
    },
    logout(state) {
      state.commit('logout');
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      router.push('login');
    }
  }
};

export default auth;

/* eslint-disable */
import axiosInstance from '../../utils/axios';
import Vue from 'vue';

const getDefaultState = () => ({
  url: '/topics',
  list: []
});

const topics = {
  namespaced: true,
  state: () => getDefaultState(),
  mutations: {
    setTopics(state, value) {
      state.list = value;
    }
  },
  actions: {
    async load({ state, commit }) {
      const loadResult = await axiosInstance.get(state.url);
      loadResult.data.forEach(topic => {
        Vue.set(topic, 'grade', 0);
        Vue.set(topic, 'comment', '');
        Vue.set(topic, 'feedbackTopicTags', []);
      });
      commit('setTopics', loadResult.data);
    },
    async getById({ state }, { id }) {
      const topicByIdResult = await axiosInstance.get(`${state.url}/${id}`);
      return topicByIdResult.data;
    },

    async create({ state }, data) {
      await axiosInstance.post(`${state.url}`, data);
    },

    async edit({ state }, data) {
      await axiosInstance.put(`${state.url}/${data.id}`, data);
    },

    async delete({ state }, { id }) {
      await axiosInstance.delete(`${state.url}/${id}`);
    }
  },
  getters: {
    english: state => state.list.find(topic => topic.name === 'English')
  }
};

export default topics;

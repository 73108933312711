import Vue from 'vue';
import Vuex from 'vuex';

import authModule from './modules/auth';
import tagsModule from './modules/tags';
import topics from './modules/topics';
import candidates from './modules/candidates';
import positions from './modules/positions';
import archivedCandidatesModule from './modules/archivedCandidates';
import interviews from './modules/interviews';
import feedbacks from './modules/feedbacks';
import templates from './modules/templates';
import recommendations from './modules/hireRecommendations';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    auth: authModule,
    tags: tagsModule,
    topics: topics,
    candidates: candidates,
    positions: positions,
    archivedCandidates: archivedCandidatesModule,
    interviews: interviews,
    feedbacks: feedbacks,
    templates: templates,
    recommendations: recommendations
  },
  getters: {}
});
